/*
 * @Description:
 * @Author:
 * @Date: 2023-03-16 13:47:08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-19 11:36:51
 */
import {request} from "./request";

// 获取液源的列表
export function getPlansListRequest(params) {
  return request({
    url: '/trade/plan/list',
    params
  })
}
export function getPlanInfoRequest(params) {
  return request ({
    url:'/trade/plan/info',
    params
  })
}

// 添加购液计划
export function addPlan(params){
  return request ({
    url: '/trade/plan',
    method: 'post',
    data:params
  })
}

// 修改购液计划
export function editPlan(params){
  return request ({
    url: '/trade/plan',
    method: 'put',
    data:params
  })
}

// 导出计划明细
export function excelPlan(params){
  return request( {
    url: '/trade/planReview/exportExcel',
    method:'get',
    params,
    responseType: "blob"
  })
}

// 导出计划列表
export function excelPlanList(params){
  return request( {
    url: '/trade/plan/exportExcel',
    method:'get',
    params,
    responseType: "blob"
  })
}

export function infoPlan(params){
  return request({
    url: `trade/planReview/info/${params.enterId}`
  })
}

export function successfulAuditRequest(params){
  return request ({
    url: '/trade/planReview/successfulAudit',
    method: 'put',
    data:params,
  })
}

export function auditFailureRequest(params){
  return request ({
    url: '/trade/planReview/auditFailure',
    method: 'put',
    data:params
  })
}

export function getPlanAuditListRequest (params){
  return request ({
    url: '/trade/planReview/list',
    params
  })
}
export function getDetailListRequest(params){
  return request({
    url: '/trade/planReview/particulars',
    params
  })
}
export function getPlanDetailsListRequest(params){
  return request({
    url: '/trade/planReview/planDetails',
    params
  })
}

// 获取购液企业的贸易企业信息
export function getEnterpriseRequest(params){
  return request({
    url: '/trade/trade/emption_enterprise/tardList',
    params
  })
}

// 获取液源
export function getLngSourceRequest(params) {
  return request ({
    url: '/trade/lngSource/list',
    params
  })
}

// 获取购液企业的下的所有液源
export function getLngRequest(params) {
  return request ({
    url: '/trade/lngSource/checklist',
    params
  })
}

export function addFunction(params){
  return request({
    url: '/platform/menu/save',
    method: 'post',
    params
  })
}

export function editFunction(params){
  return request({
    url: '/platform/menu/update',
    method: 'post',
    params
  })
}

export function deleteFunction (params){
  return request({
    url: '/platform/menu/del',
    method: 'post',
    params
  })
}
