<!--
 * @Description:
 * @Author:
 * @Date: 2023-03-08 16:21:53
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2024-03-07 17:00:40
-->
<template>
  <div>
    <el-row>
      <el-col>
        <el-button type="text"
                   @click="enteringButton(scope)">资金录入</el-button>
      </el-col>
    </el-row>
    <el-dialog title="资金录入"
               :visible.sync="setRightDialogVisible"
               :append-to-body='true'
               :modal-append-to-body='true'
               :before-close="resetForm"
               width="50%">
      <el-form :model="ruleForm"
               :rules="rules"
               ref="ruleForm"
               label-width="120px"
               label-position="left"
               style="margin-left:40px"
               class="demo-ruleForm">
        <el-form-item label="购液企业:"
                      prop="name">
          {{ ruleForm.name }}
        </el-form-item>
        <el-form-item label="账户余额:"
                      prop="balance">
          {{ ruleForm.balance || 0 }}
        </el-form-item>
        <el-form-item label="录入余额:"
                      prop="entering"
                      style="width: 500px">
          <el-input v-model="ruleForm.entering"
                    oninput="if (value.slice(0,value.indexOf('-')+2)) {if(isNaN(value.slice(1,value.indexOf('-')+999))) { value = null }}
                              if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+99)}
                              if (value.slice(0,value.indexOf('-')+2)!=='-'){if(isNaN(value)){value=null}}"
                    placeholder="请输入要录入的余额"
                    @change="ChangeEnter"
                    @input="handleInput"></el-input>
        </el-form-item>
        <el-form-item label="录入后余额:"
                      prop="newBalance">
          {{ ruleForm.newBalance || 0 }}
        </el-form-item>
        <el-form-item label="备注:"
                      prop="remarks"
                      style="width: 500px">
          <el-input v-model="ruleForm.remarks"
                    placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="转账凭证:"
                      prop="imageLists"
                      required>
          <el-upload ref="upload"
                     :action="uploadUrl"
                     :on-preview="handleImgPreview"
                     :on-remove="handleImgRemove"
                     list-type="picture-card"
                     :file-list="imageLists"
                     :limit="9"
                     :class="{'demo-httpRequestImg':httpRequestImg}"
                     :headers="imgUploadHeaders"
                     :on-success="handleImgUploadSuccess">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="对应计划:">
          <el-date-picker v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="onSubmit">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="已选择计划:" v-if="this.dateValue!==''&&this.dateValue!==null">
          {{orderName}}
        </el-form-item>
        <el-table ref="multipleTable" :data="tableData" border tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" v-if="this.dateValue!=''&&this.dateValue!=null">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="计划日期" width="120">
            <template slot-scope="scope">{{ scope.row.planDate }}</template>
          </el-table-column>
          <el-table-column prop="orderNumber" label="订单号" width="120"></el-table-column>
          <el-table-column label="配送方式" show-overflow-tooltip>
             <template slot-scope="scope">{{ scope.row.distributionType==1?'自提':'配送' }}</template>
          </el-table-column>
          <el-table-column prop="tractorNumber" label="车辆信息" show-overflow-tooltip></el-table-column>
          <el-table-column prop="driverInfo" label="司机信息"  show-overflow-tooltip></el-table-column>
          <el-table-column prop="escortInfo" label="押运员信息" show-overflow-tooltip></el-table-column>
          <el-table-column prop="lngSourceName" label="液源" show-overflow-tooltip></el-table-column>
          <el-table-column prop="listingPrice" label="挂牌价(元/吨)" show-overflow-tooltip></el-table-column>
          <el-table-column prop="weight" label="预估总重量(吨)" show-overflow-tooltip></el-table-column>
          <el-table-column prop="aggregateAmount" label="预估总金额(元)" show-overflow-tooltip></el-table-column>
        </el-table>
      </el-form>
      <el-dialog title="图片预览"
                 :visible.sync="previewDialogVisible"
                 width="50%"
                 append-to-body>
        <img style="width: 100%;"
             :src="previewImgUrl"
             class="previewImg">
      </el-dialog>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="resetForm()">取 消</el-button>
        <el-button type="primary"
                   @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { FundEntryRequest, GetModalList } from "network/financial";

export default {

  name: "CapitalEnteringModal",
  props: {
    scope: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      tableData: [],
      multipleSelection: [],
      property: 'value',
      dateValue: [],
      setRightDialogVisible: false,
      httpRequestImg: false,
      uploadUrl: this.api.imageUrl + "/trade/purchaseorder/uploadImg",
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: localStorage.getItem('token')
      },
      imageLists: [],
      previewImgUrl: '',
      previewDialogVisible: false,
      ruleForm: {
        name: '',
        balance: '',
        entering: '',
        newBalance: '',
        remarks: '',
        imageLists: [],
      },
      rules: {
        entering: [
          { required: true, message: '请输入要录入的余额', trigger: 'blur' }
        ],
        remarks: [
          { required: true, message: '请输入备注', trigger: 'blur' }
        ],
        imageLists: [
          { required: true, message: '请上传转账凭证', trigger: 'blur' }
        ],
      },
      orderId: '',
      orderName: ''
    };
  },
  created() {

  },
  methods: {

    getLists () {
      GetModalList({
        startDate: this.dateValue[0],
        endDate: this.dateValue[1],
        stationId: this.scope.stationId
      }).then(res => {
        console.log(res);
        let result = res.data
        this.tableData = result.data.list
      })
    },

    timestampToTime(){
      let date = new Date();
      let Y = date.getFullYear() + '';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '';
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '';
      return Y + '-' + M + '-' + D ;
    },

    onSubmit(){
      console.log(this.dateValue);
      if(this.dateValue!=''||this.dateValue!=null){
        this.getLists()
      }
    },

    handleInput (value) {
      if (value != '') {
        if (value.indexOf('.') > -1) {
          this.ruleForm.entering = value.slice(0, value.indexOf('.') + 3)
        } else {
          this.ruleForm.entering = value
        }
      }
    },
    enteringButton (scope) {
      this.setRightDialogVisible = true
      this.ruleForm.name = scope.stationName
      this.ruleForm.balance = scope.balance
      this.ruleForm.newBalance = scope.balance
      let date = this.timestampToTime()
      this.dateValue = [date,date]
      this.getLists()
    },
    submitForm (formName) {
      if (this.imageLists.length == 0) {
        this.alertMessage('请先上传转账凭证', 'error');
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let newImage = []
            for (let i = 0; i < this.imageLists.length; i++) {
              // newImage.push(this.imageLists[i].url.replace(new RegExp("https://test.sysongy.mobi:7000/trade/images/",""),""))
              newImage.push(this.imageLists[i].url.replace(new RegExp(this.api.imageUrl + "/trade/images/", ""), ""))
            }
            FundEntryRequest({
              stationId: this.scope.stationId,
              imagePath: newImage.toString(),
              remark: this.ruleForm.remarks,
              amount: this.ruleForm.entering,
              orderId: this.orderId
            }).then(res => {
              let result = res.data;
              if (result.code !== 200) {
                return this.alertMessage(result.msg, 'error');
              }
              this.alertMessage('修改成功', 'success');
              // 隐藏弹窗
              this.setRightDialogVisible = false
              this.$refs.ruleForm.resetFields();
              this.$refs.upload.clearFiles()
              this.imageLists = []
              this.$emit('goods-list');
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    resetForm () {
      this.setRightDialogVisible = false
      this.$refs.ruleForm.resetFields();
      this.imageLists = []
    },

    handleSelectionChange (val) {
      let newId = ''
      let newOrderId = ''
      val.map((item)=> {
        newId =item.orderId+ ',' +newId
        newOrderId = item.orderNumber+ ',' + newOrderId
      })
      console.log(newId);
      this.orderId = newId;
      this.orderName = newOrderId
    },

    // 处理图片预览效果
    handleImgPreview (file) {
      let reg2 = new RegExp(",");
      this.previewImgUrl = file.url
      this.previewDialogVisible = true;
    },
    // 处理移除图片的操作
    handleImgRemove (file) {
      // let reg2 = new RegExp(",");
      let removeUrl = file.url;
      let index = this.imageLists.findIndex(item => {
        return item.url === removeUrl;
      });
      this.imageLists.splice(index, 1);
      this.httpRequestImg = this.imageLists.length >= 9
    },

    ChangeEnter (value) {
      console.log(value);
      this.ruleForm.newBalance = (this.ruleForm.balance * 100 + Number(value) * 100) / 100
    },

    // 监听图片上传成功
    handleImgUploadSuccess (response) {
      let reg2 = new RegExp(",");
      let imageUrl = response.data.replace(reg2, '')
      this.$data.ruleForm.imageLists.push(imageUrl)
      this.imageLists.push({
        name: '',
        url: this.api.imageUrl + '/trade/images/' + imageUrl
      })
      this.httpRequestImg = this.imageLists.length >= 9
    },

  }
}
</script>
<style  scoped>
.el-button--text {
  color: var(--theme_bg_color);
}
.el-button:focus,
.el-button:hover {
  border: 0px;
  border-color: #fff;
  color: var(--theme_bg_color);
}
.el-button--primary {
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color);
}
.el-button--primary:focus,
.el-button--primary:hover {
  border-color: var(--theme_bg_color);
  color: #fff;
}
.demo-httpRequestImg >>> .el-upload--picture-card {
  display: none !important;
}
</style>