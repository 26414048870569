<template>
  <div style="height: 100%">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>财务中心</template>
      <template v-slot:secondMenu>{{ type==1?'资金管理':'资金明细' }}</template>
    </breadcrumb-nav>
    <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
      {{ type==1?'资金管理':'资金明细' }}
      </div>
      <div class="radioBox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="贸易企业" v-if='type==2'>
          <el-select v-model="formInline.region" placeholder="请选择" @change="onSubmit" clearable>
            <el-option
              v-for="item in enterpriseList"
              :key="item.tardId"
              :label="item.tardName"
              :value="item.tardName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="购液企业" v-if='type==1'>
          <el-select v-model="formInline.region" placeholder="请选择" @change="onSubmit" clearable>
            <el-option
              v-for="item in tradList"
              :key="item.stationId"
              :label="item.stationName"
              :value="item.stationName">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div v-if="type==1"><el-button type="primary" @click="uploadButton">账户充值明细导出</el-button></div>
      </div>
      <!-- table表格区域 -->
      <el-table highlight-current-row :data="goodsList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
        <el-table-column :label="type==1?'购液企业名称':'贸易企业名称'" :prop="type==1?'stationName':'tardName'" ></el-table-column>
        <el-table-column label="账户余额" prop="balance" ></el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <div style="display: flex">
              <capital-list-modal :role-id="type==1?scope.row.stationId:type==2?scope.row.tradId:''" :title="type==1?scope.row.stationName:type==2?scope.row.tardName:''" />
              <div style="marginLeft: 15px">
                <capital-entering-modal :scope='scope.row' v-if="type==1" @goods-list='updateRoleList'/>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="float: right;">
          <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.pageNo"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
      </div>
    </el-card>


  </div>
</template>

<script>
  import BreadcrumbNav from "../../common/BreadcrumbNav";
  import { GetTradeListRequest,GetFactoryRequest,getEmptionPageRequest,emptionListRequest,exportRequests } from "network/financial";
  import CapitalListModal from './CapitalCompontents/CapitalListModal.vue';
  import CapitalEnteringModal from './CapitalCompontents/CapitalEnteringModal.vue';
  import { getEnterpriseRequest } from 'network/plan'



  export default {
    name: "List",
    components: {
      BreadcrumbNav,
      CapitalListModal,
        CapitalEnteringModal
    },
    data() {
      return {
        queryInfo: {
          query: "",
          pageNo: 1,
          pageSize: 10
        },
        formInline: {
          user: '',
          region: ''
        },
        goodsList: [],
        total: 0,
        type: localStorage.getItem('type'),
        enterpriseList: [],
        tradList: []
      }
    },
    created() {
      this.getGoodsList();
      this.getEnterpriseList();
      this.getTardList()
    },
    methods: {

      getEnterpriseList(){
        getEnterpriseRequest({
          stationId: localStorage.getItem('enterpriseId')
        }).then(res=>{
          let result = res.data
          if(result.code !== 200 ) {

          }
          this.enterpriseList = result.data
        })
      },
      getTardList(){
        emptionListRequest({
          tardId: localStorage.getItem('enterpriseId')
        }).then(res=>{
          let result = res.data
          if(result.code !== 200 ) {

          }
          this.tradList = result.data
        })
      },
      onSubmit() {
        this.getGoodsList()
      },
      getGoodsList() {
        if(this.type==1){
          GetTradeListRequest({
          page:this.queryInfo,
              stationName: this.formInline.region
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
        }
        if(this.type == 2){
          getEmptionPageRequest({
          page:this.queryInfo,
          stationId: localStorage.getItem('enterpriseId'),
          tardName:this.formInline.region
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
        }
      },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNo = 1
        this.queryInfo.pageSize = newSize;
        this.getGoodsList();
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNo = newPage;
        this.getGoodsList();
      },
      updateRoleList() {
        this.getGoodsList();
      },
      uploadButton(){
        exportRequests({}).then(res=>{
          var content = res.headers['content-disposition'];
          var name = content && content.split(';')[1].split('filename=')[1];
          var fileName = decodeURIComponent(name)
          console.log(res,fileName);
          this.downloadCallback(res, fileName);
        })
      },
          //生成下载文件
    downloadCallback(res, fileName){
      const content = res.data;
      const blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    }
  }
</script>

<style scoped>

.radioBox{
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.el-button {
    background-color: var(--theme_bg_color);
    border: 1px solid var(--theme_bg_color)
}

</style>

