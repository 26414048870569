<template>
<div>
    <el-row>
      <el-col>
        <el-button type="text" @click="openModal(roleId)">往来记录</el-button>
      </el-col>
    </el-row>

  <el-dialog
            title="资金往来明细"
            :visible.sync="setRightDialogVisible"
            width="70%">
      {{title}}
      <el-table highlight-current-row  :data="goodsList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
        <!-- <el-table-column label="交易时间" prop="price"></el-table-column> -->
        <el-table-column label="交易时间" prop="add_time" >
          <template slot-scope="scope">
            {{scope.row.createDate}}
          </template>
        </el-table-column>
        <el-table-column label="金额" prop="amount"></el-table-column>
        <el-table-column label="交易类型" prop="changeType">
          <template slot-scope="scope">
            {{ scope.row.changeType=='1'?'订单扣款':scope.row.changeType==2?'账户充值':scope.row.changeType==3?'对账退款':scope.row.changeType==4?'订单退款':'--' }}
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="转账凭证" prop="">
          <template slot-scope="scope">
            <!-- {{ scope.row.changeType=='1'||scope.row.changeType==3?'--': }} -->
            <el-button v-if="scope.row.changeType==2" type="text" @click="lookImage(scope.row.imagePath)">查看</el-button>
            <div v-if="scope.row.changeType!==2">--</div>
          </template>
        </el-table-column>
        <el-table-column label="对应计划" v-if="type==1" width="150px" :show-overflow-tooltip="true">
          <template slot-scope="scope">
                  {{scope.row.orderNumber==null?'':scope.row.orderNumber.toString()}}
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="createUser" ></el-table-column>
      </el-table>
      <!-- <template> -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pagenum"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      <!-- </template> -->
      <el-dialog
        title="图片预览"
        :visible.sync="previewDialogVisible"
        width="50%" append-to-body>
        <template v-if="previewDialogVisibleText == false">
          <img v-for="item in urlList"  :src="item" class="previewImg" @click="BlowUpImage(item)">
        </template>
      <span v-if="previewDialogVisibleText">暂无图片</span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibleOne" width="30%" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRightDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setRightDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>
<script>
  import { BalanceRequest,TradeBalanceRequest } from "network/financial";

export default {
  name: "CapitalListModal",
  props: {
    roleId: {
      // type: Object,
      default() {
        return {}
      }
    },
    title: {
        default() {
            return {}
        }
    }
  },
  data() {
    return {
      property: 'value',
      type: localStorage.getItem('type'),
      setRightDialogVisible: false,
      previewDialogVisibleText: false,
      dialogVisibleOne: false,
      previewDialogVisible: false,
      previewImgUrl: '',
      urlList: [],
      queryInfo: {
          query: "",
          pageNo: 1,
          pageSize: 10
        },
      total: 5,
      goodsList: [ ]
    };
  },
  // created() {
  //     this.getList();
  //   },
  methods: {
    getList(id){
      if(this.type==1){
        TradeBalanceRequest({
          ...this.queryInfo,
          stationId: id
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      }
      if(this.type == 2){
        BalanceRequest({
          ...this.queryInfo,
          tradeId: id
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      }

    },
    openModal(id,title){
      this.setRightDialogVisible=true
      this.getList(id)
    },
    lookImage(url){
      this.previewDialogVisible = true;
        if(url==''){
         this.previewDialogVisibleText = true
        }
        else{
          let arr = url.split(',')
          this.urlList = arr.map((item)=> this.api.imageUrl+'/trade/images/'+item)
        }
    },
    BlowUpImage(url){
        this.dialogImageUrl=url
        this.dialogVisibleOne = true
      },
    handleSizeChange(newSize) {
      this.queryInfo.pageNo = 1
        this.queryInfo.pageSize = newSize;
        this.getList(this.roleId)
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNo =Number(newPage);
        this.getList(this.roleId)
      },
  }
}
</script>
<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
  .previewImg{
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
<style>
.el-tooltip__popper{
    max-width:300px;
    padding-bottom: 5px!important;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
 
  }
  .el-tooltip__popper,.el-tooltip__popper.is-dark{
    background:rgb(48, 65, 86) !important;
    color: #fff !important;
    line-height: 24px;
  }
</style>