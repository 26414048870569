/*
 * @Description:
 * @Author:
 * @Date: 2023-03-24 10:20:13
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2024-03-12 16:25:23
 */
import {request} from "./request";

// 购液企业对账信息列表
export function getFinancialListRequest(params) {
  return request({
    url: '/trade/reconciliation/emption/page',
    method: 'post',
    data:params
  })
}

// 购液企业确认对账信息
export function confirmRequest(params) {
  return request({
    url: `/trade/reconciliation/emption/confirm/${params}`,
    method: 'put',
  })
}

// 贸易企业对账信息列表
export function getFinancialListOneRequest(params) {
  return request ({
    url: '/trade/reconciliation/trade/page',
    method: 'post',
    data: params
  })
}

// 贸易企业导出发票信息
export function uploadRequest(params) {
  return request ({
    url: '/trade/reconciliation/trade/trade/exportExcel',
    responseType: "blob",
    params
  })
}

// 购液企业导出未对账的发票信息
export function uploadEmpRequest(params) {
  return request ({
    url: '/trade/reconciliation/trade/liquidPurchase/exportExcel',
    responseType: "blob",
    params
  })
}

// 购液企业导出已对账的发票信息
// export function uploadNoEmpRequest(params) {
//   return request ({
//     url: '/trade/reconciliation/trade/trade/exportExcel',
//     responseType: "blob",
//     params
//   })
// }


// 贸易企业未对账订单数量(发票管理列表用)
export function getUnreconciledListRequest(params) {
  return request ({
    url: '/trade/reconciliation/emption/notInvoiced',
    method: 'post',
    data: params
  })
}

	// 贸易企业未对账订单数量
  export function getReconciliationListRequest(params){
    return request ({
      url: '/trade/reconciliation/trade/not_reconciled/page',
      method: 'post',
      data: params
    })
  }

// 购液企业未对账订单数量
export function getNotReconciledRequest(params) {
  return request ({
    url: '/trade/order/not_reconciled/order/page',
    method: 'post',
    params
  })
}

// 查询未对账订单
export function getReconciledRequest(params){
  return request({
    url: '/trade/order/not_reconciled/page',
    method: 'post',
    data: params
  })
}

export function getEditReconciledRequest(params){
  return request({
    url: '/trade/order/reconciliation_editor/page',
    method: 'post',
    data: params
  })
}

// 开始对账
export function TradeReconciliation(params) {
  return request ({
    url:"/trade/reconciliation/trade",
    method: 'post',
    data: params
  })
}

// 编辑对账
export function TradeReconciliationEdit(params) {
  return request ({
    url:"/trade/reconciliation/trade/start_reconciliation",
    method: 'post',
    data: params
  })
}

// 订单详情
export function TradeDetailsRequest(params) {
  return request ({
    url: `/trade/reconciliation/trade/${params.id}/orders`,
    method: 'post',
    data: {
      pageNo: params.pageNo,
      pageSize: params.pageSize
    }
  })
}

export function emptionListRequest(params) {
  return request ({
    url: '/trade/trade/emption_enterprise/emptionList',
    method: 'post',
    params
  })
}

// 购液企业订单详情
export function orderRequest(params){
  return request ({
    url: `/trade/reconciliation/emption/${params.id}/orders`,
    method: 'post',
    data: {
      pageNo: params.pageNo,
      pageSize: params.pageSize
    }
  })
}

export function orderRequests(params){
  return request ({
    url: `/trade/reconciliation/emption/${params.id}/orders`,
    method: 'get',
  })
}

// 删除对账信息
export function DeleteTradeRequest (params){
  return request ({
    url: `/trade/reconciliation/trade/${params}`,
    method: 'delete',
  })
}

// 购液企业资金余额

export function BalanceRequest(params) {
  return request({
    url: `/trade/balance_change_log/epntion/${params.tradeId}/page`,
    method: 'post',
    data: {
        pageNo: params.pageNo,
        pageSize: params.pageSize,
    }
  })
}

// 贸易企业资金余额

export function TradeBalanceRequest(params) {
  return request ({
    url: `/trade/balance_change_log/trade/${params.stationId}/page`,
    method: 'post',
    data: {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
    }
  })
}

// 查询购液企业信息
export function GetTradeListRequest(params) {
  return request ({
    url: '/trade/trade/emption_enterprise/page',
    method: 'post',
    data: params
  })
}

export function getEmptionPageRequest(params){
  return request ({
    url: '/trade/trade/emption_enterprise/emptionPage',
    method: 'post',
    data: params
  })
}

// 查询贸易企业信息
export function GetFactoryRequest(params) {
  return request ({
    url: '/trade/factory/page',
    method: 'post',
    data: params
  })
}

// 资金录入
export function FundEntryRequest(params) {
  return request ({
    url: `trade/balance_change_log/trade/${params.stationId}/fund_entry`,
    method: 'post',
    data: params
  })
}

//资金录入列表查询
export function GetModalList(params){
  return request({
    url: 'trade/balance_change_log/epntion/list',
    method: 'GET',
    params
  })
}

// 发起申请
export function EmptionRequest(params) {
  return request ({
    url:`/trade/invoice/emption/${params.reconciliationId}`,
    method : 'put',
    data: params
  })
}
// 取消申请

export function CancelRequest(params){
  return request ({
    url:`/trade/invoice/emption/cancel/${params.id}`,
    method: 'put',
  })
}

// 确认开票
export function ConfirmRequest(params){
  return request ({
    url : `/trade/invoice/trade/${params.id}`,
    method: 'put',
    data: params
  })
}

// 获取发票信息
export function InvoiceInfoRequest(){
  return request ({
    url: '/trade/reconciliation/emption/queryInvoiceInfo',
    method:'post'
  })
}

// 获取贸易企业发票信息
export function InvoiceInfoRequests(params){
  return request ({
    url: `/trade/reconciliation/emption/queryInvoiceInfos/${params.id}/${params.reconciliationId}`,
    method:'post'
  })
}

// 账户充值明细导出
export function exportRequests(params){
  return request({
    url: `/trade/balance_change_log/trade/export`,
    responseType: 'blob',
    method: 'get',
  })
}

export function exportExcel(params){
  return request ({
    url:'/trade/order/not_reconciled/exportExcel',
    responseType: "blob",
    method: 'get',
    params
  })
}

export function accountExcel(params) {
  return request({
    url: '/trade/reconciliation/emption/exportExcel',
    responseType: "blob",
    method: 'get',
    params
  })
}

export function accountExcelOne(params) {
  return request({
    url: '/trade/reconciliation/trade/amdin/exportExcel',
    responseType: 'blob',
    method: 'get',
    params
  })
}

export function uploadOneRequest(params){
  return request ({
    url: '/trade/reconciliation/trade/liquid/exportExcel',
    responseType: 'blob',
    method: 'get',
    params
  })
}

export function updateByTard(params){
  return request({
    url: `/trade/reconciliation/trade/${params.reconciliationId}/updateFilePathByTard`,
    method: 'post', 
    data: params
  })
}
export function updateByEmption(params){
  return request({
    url: `/trade/reconciliation/trade/${params.reconciliationId}/updateFilePathByEmption`,
    method: 'post',
    data: params
  })
}

export function changePage(params){
  return request({
    url:'/trade/order/reconciliation_editor/pages',
    method: 'post',
    data: params
  })
}

export function changePageList(params){
  return request({
    url:'/trade/order/reconciliation_editor/page',
    method: 'post',
    data: params
  })
}

// 获取购液企业发票列表
export function getLiquidList(params){
  return request({
    url:'/trade/system/invoice/Liquid/list',
    params
  })
}

// 获取贸易企业发票列表
export function getTradeList(params){
  return request({
    url:'/trade/system/invoice/Trade/list',
    params
  })
}

// 购液企业申请发票
export function systemInvoice(params) {
  return request({
    url: '/trade/system/invoice',
    method: 'post',
    data: params
  })
}

// 购液企业取消申请发票
export function cancelInvoice(params) {
  return request({
    url: '/trade/system/invoice',
    method: 'PUT',
    data: params
  })
}


// 贸易企业发票通过
export function passEdit(params) {
  return request({
    url: '/trade/system/invoice/passEdit',
    method: 'put',
    data: params
  })
}

// 拒绝发票通过
export function downEdit(params){
  return request({
    url:'/trade/system/invoice/downEdit',
    method: 'put',
    data: params
  })
}